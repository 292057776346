<template>
  <div class="exhibitor-list  animate__animated animate__fadeIn animate__faster">
    <van-pull-refresh 
      v-model="refreshing" 
      @refresh="handlePullDownRefresh"
    >
      <van-list 
        v-model="loading" 
        :finished="finished" 
        :offset="10" 
        :immediate-check="false" 
        :loading-text="loadingText" 
        :finished-text="finishText" 
        @load="handleReachBottom"
      >
        <!-- <div style="height: 56px;"></div> -->
        <!-- 头部 -->
        <HeaderSearch placeholderText="企业名称" :keywords="params.name" @handleChange="handleChange">
        </HeaderSearch>
        <!-- 展商列表 -->
        <div class="list-wrapper">
          <block  v-for="(item,index) in list" :key="index">
            <commonProductItem customClass="mt-10 p-10" :meetingName="meetingName" :item="item"></commonProductItem>
          </block>
        </div>
      </van-list>
    </van-pull-refresh>
    <!-- 全局loading加载 -->
    <div v-if="firstLoading" class="bg-white p-fixed inset-0 d-flex flex-center"
				style="z-index: 1000000;">
				<firstLoading></firstLoading>
    </div>
    
  </div>
</template>
<script>
import commonProductItem from '@/components/commonProductItem.vue'
import HeaderSearch from '@/components/HeaderSearch.vue'
import firstLoading from '@/components/firstLoading.vue'
let arr = []
export default {
  name: 'productList',
  components:{
    HeaderSearch,
    commonProductItem,
    firstLoading,
  },
  data() {
    return {
      firstLoading: true,
      list: [],
      loading: false, //  控制数据的加载，false 代表没有正在刷新，true 代表正在刷新
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      refreshing: false, // 下拉状态是否处于加载中状态
      params: {
        pageNum: 1,
        pageSize: 12,
        name: '',
        meetingCode: '',
      },
      meetingName:'',
      total: 0, // 总条数
      pages: 0, // 总页数
      loadingText: '玩命加载中...',
      finishText: '没有更多了',
      // headers====
      eventKey: '',
      appKey: '',
      token: '',
     
    }
  },
  methods: {
    initData() {
      // 生成数据
      for (let i = 0; i < 40; i++) {
        arr.push({
          id: new Date().getTime(),
        })
      }
      this.getData()
    },
    handleChange(e) {
      console.log(`output->e`, e)
      // if(!e.trim()) return this.$toast("请输入企业名称~")
      this.params.pageNum = 1
      this.params.likeExhibitorName = e
      this.list = []
      this.getData()
    },
    async getData() {
      try {
        this.loading = true
        let { code, list, total, pages, pageNum } = await this.$getList(this.params, arr)
        console.log("list", list)
        console.log("pages", pages)
        console.log("pageNum", pageNum)
        this.list = this.params.pageNum == 1 ? [...list] : [...this.list, ...list];
        this.total = total
        this.pages = pages
        // 追加完成后关闭loading
        this.loading = false
        this.finishText = this.list.length ? '没有更多了' : '暂无更多数据'
      } catch (error) {
        if (this.params.pageNum > 0) {
          this.params.pageNum--
        }
        console.log(`output->error`, error)
      }
    },
    // 下拉刷新
    handlePullDownRefresh() {
      setTimeout(() => {
        this.params.pageNum = 1
        this.getData()
        this.refreshing = false
        this.finished = false // 重新加载
        if (!this.refreshing) {
          this.$toast.success('刷新成功')
        }
      }, 1000)
    },
    // 上拉加载
    handleReachBottom() {
      console.log('上拉加载')
      console.log(`output->this.params.pageNum`, this.params.pageNum)
      if (this.params.pageNum < this.pages) {
        this.params.pageNum++
        this.getData().finally(() => {
          this.loading = false
        })
      } else {
        this.finished = true
      }
    },
  },
  async mounted() {
    try {
      this.firstLoading = true
      await this.initData()
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    } catch (error) {
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    }
  },
}
</script>
<style lang="less" scoped>
/* 定位 - 固定顶部 */
.p-fixed {
  position: fixed;
}
.fixed-top {
	position: fixed;
	top: 0px;
	right: 0;
	left: 0;
	z-index: 1000;
}
.inset-0 {top: 0;bottom: 0;left: 0;right: 0;}
.exhibitor-list {
  min-height: 100vh;
  background-color: #F5F5F5;
  position: relative;
  .van-pull-refresh {
    min-height: 100vh
  }
}
.list-wrapper {
  box-sizing: border-box;
  padding: 0 10px;
}

</style>