<template>
  <!-- 头部搜索 -->
  <div class="search-container">
    <div class="search-box">
    <van-search
      v-model="valueText"
      :placeholder="placeholderText"
      @search="handleSearch"
    >
    <template #left-icon	>
         <span class="iconfont wemeet-sousuo"  @click="handleSearch"></span>
    </template>
    </van-search>
    </div>
  </div>
</template>
<script>
import { debounce } from '@/utils/debounce.js'
export default {
  name: 'HeaderSearch',
  props: {
    placeholderText: {
      type: String,
      default: '企业名称',
    },
    keywords: {
      type: String,
      default: '',
    },
  },
  components: {},
  data() {
    return {
      valueText:this.keywords
    }
  },
  methods: {
    handleSearch:debounce(function(e) {
      // 一定是input事件
      this.$emit("handleChange",this.valueText)
    }),
    onCancel() {
      console.log(`output->取消`)
    }
  },
  
  
  mounted() {},
}
</script>
<style lang="less" scoped>
/* 定位 - 固定顶部 */
.fixed-top {
	position: fixed;
	top: 0px;
	right: 0;
	left: 0;
	z-index: 1000;
}
.search-container {
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  padding: 10px;
  height: 56px;
  align-items: center;
}
.search-box {
  width: 100%;
  height: 37px;
  border-radius: 20px;
  flex: 1;
  background-color: #F5F5F5;
  box-sizing: border-box;
  position: relative;
  padding: 0 15px;
  .van-search {
    height: 37px;
    box-sizing: border-box;
    padding: 0;
    .van-search__content  {
      padding-left: 0;
      .van-cell  {
        display: flex;
        align-items: center;
        height: 37px;
        .van-cell__value {
          display: flex;
          align-items: center;
        }
        /deep/ .van-field__left-icon {
          margin-right: 10px;
        }
      }
     
    }
  }
  input::-webkit-input-placeholder {
    color: #cccccc;
    font-size: 13px;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #cccccc;
    font-size: 13px;
  }
  　　 input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #cccccc;
    font-size: 13px;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #cccccc;
    font-size: 13px;
  }
}

.wemeet-sousuo {
  position: relative;
  top: 2px;
  font-size: 22px;
  color:#CCCCCC;
}

</style>